<template>
    <img :class="classList"
         :src="src"
         ref="img"
         @click="$emit('click')"
         @load="handleLoaded"
         @error="handleError" />
</template>

<script>
    export default {
        name: "lazy-img",
        props:{
            src: {
                type: String,
                default: ""
            },
            className: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                portrait: false,
                loaded: false
            }
        },
        watch:{
            src() {
                this.loaded = false;
                this.portrait = false;
            }
        },
        computed: {
            classList() {
                let cls = (this.className+"").split(" ");
                if (!cls.includes("init")) {
                  cls.push("init");
                }

                if (this.loaded && !cls.includes("loaded")) {
                    cls.push("init", "loaded");
                }

                if (this.portrait && !cls.includes("portrait")) {
                    cls.push("portrait");
                }

                return cls;
            }
        },
        methods:{
            handleLoaded() {
                this.loaded = true;
                const img = this.$refs.img;
                const rect = img.getBoundingClientRect();
                if (rect.height > rect.width) {
                  this.portrait = true;
                }
            },
            handleError() {
                this.loaded = false;
            }
        }
    }
</script>

<style lang="scss">
img.init {
    opacity: 0;
    transition: all 0.5s ease;
    &.loaded {
        opacity: 1;
    }
}
</style>