import axios from "axios"

const endPoint = global.API_URI || "http://dev.svg/";

const http = axios.create({
    baseURL: endPoint
});
http.interceptors.response.use(function (resp) {
    let json = resp.data;
    if (json && json.status) {
        return json.data;
    }
    if (json && json.error) {
        return Promise.reject(json.error);
    }

    return Promise.reject();
});

export default {
    getOSSParams(filename) {
        return http.get("/api/oss/params", {
            params: {
                filename
            }
        })
    },
    saveResult(filename) {
        let formData = new FormData();
        formData.append('filename', filename);
        return http.post("/api/save", formData)
    },
    images() {
        return http.get("/api/images")
    }
}