<template>
    <transition name="fade">
    <div class="box-wrapper" v-if="showFlag">
        <div class="img-box">
            <a @click="prev" class="controls prev"><span class="iconfont icon-page-previous"></span></a>
            <a @click="next" class="controls next"><span class="iconfont icon-page-next"></span></a>
            <a :href="currentImage" target="_blank"><lazy-img :src="currentImage"></lazy-img></a>
            <a @click="hide" class="btn btn-close"><span class="iconfont icon-close"></span></a>
        </div>
    </div>
    </transition>
</template>

<script>
    import vueImg from "./lazy-img";

    export default {
        name: "light-box",
        components: {
            "lazy-img": vueImg
        },
        props:{
            images: {
                type: Array,
                default() {
                    return []
                }
            }
        },
        data() {
            return {
                showFlag: false,
                index: 0
            }
        },
        computed: {
            currentImage() {
                return this.images[this.index];
            }
        },
        methods:{
            show() {
                this.showFlag = true;
            },
            hide() {
                this.showFlag = false;
            },
            next() {
                let current = 0+this.index;
                current++;
                if (current >= this.images.length) {
                    current = 0;
                }
                this.index = current;
            },
            prev() {
                let current = 0+this.index;
                current--;
                if (current < 0) {
                    current = (this.images.length - 1);
                }
                this.index = current;
            },
            current(i) {
                this.index = i;
                this.showFlag = true;
            }
        }
    }
</script>

<style lang="scss">
@import "assets/iconfont.css";
.box-wrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    color:#ffffff;
    position: fixed;
    font-size: 0.58rem;
    left: 0;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-close {
        position: absolute;
        right: 1em;
        top: 1em;
        cursor: pointer;
        z-index: 10;

        .iconfont {
            font-size: 2em;
        }
    }
    .img-box {
        width: 90vw;
        max-height: 90vh;
        border: 2px solid #ffffff;
        margin: 1em auto;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        background: #000000;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            &.portrait {
              height: 100%;
              max-height: 50vh;
              width: auto;
            }
            width: 100%;
            height: auto;
            display: inline-block;
        }
    }
    .controls {
        position: absolute;
        padding: 0 2em;
        height: 100%;
        cursor: pointer;
        transition: all 0.5s ease;
        opacity: 0;

        .iconfont {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 2em;
        }
        &.next {
            right: 0;
            &:hover {
                opacity: 1;
                background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            }
        }
        &.prev {
            left: 0;
            &:hover {
                opacity: 1;
                background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
            }
        }
    }
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter {
    opacity: 1;
}
.fade-leave-to {
    opacity: 0;
}
</style>