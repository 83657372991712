<template>
    <div :class="classList">
        <div class="wrapper" :class="editMode ? 'editable' : ''">
            <ul :key="k" class="container" v-for="(row, k) in list">
                <li :key="index" :class="item.class" v-for="(item, index) in row" @click="markItem((k*row.length)+(index+1))">
                    <lazy-img v-if="item.index >= 0"
                         @click="showBox(item.index)"
                         :src="item.thumb"
                              :class-name="item.class"></lazy-img>
                    <span>{{(k*row.length)+(index+1)}}</span>
                </li>
            </ul>
        </div>
        <vue-plupload ref="uploader"
                      v-if="false"
                      @added="handleAdded"
                      @uploaded="handleUploaded"
                      text="上传你的照片"></vue-plupload>
        <vuelight-box ref="box"
                :images="images"></vuelight-box>
    </div>
</template>

<script>
    import service from "./service/svgService";
    import vuelightBox from "./light-box";
    import vueImg from "./lazy-img";
    import vuePlupload from "./vue-plupload.vue";
    import wxService from "./service/wxService";

    const generateRange = (count) => {
      return Array.from(new Array(count)).map((row, index) => {
        return index + 1;
      });
    }

    const defineColumns = generateRange(15);
    const defineRows = generateRange(10);


    export default {
        name: 'App',
        components: {
            vuePlupload,
            "lazy-img": vueImg,
            vuelightBox
        },
        data() {
            return {
                inWeChat: false,
                classList: ["wrap"],
                images: [],
                // maskIndexList: [22,61,62,65,64,101,102,104,106,105,76,77,75,114,115,116,113,153,154,155,194,193,192,152,195,233,234,232,231,271,272,273,312,311,310,270,349,309,350,351,352,390,391,389,388,348,428,427,429,430,469,468,467,466,506,507,508,509,141,142,143,144,145,146,147,148,188,187,186,185,184,183,182,181,221,220,260,261,262,263,264,265,266,267,227,226,225,224,223,222,228,300,301,302,303,304,305,306,307,340,341,342,343,344,345,346,347,386,385,384,383,380,381,379,423,424,425,426,462,463,464,465,502,503,504,505,542,543,544,545,546,547,548,541,581,582,583,584,585,586,587,626,625,624,623,622,621,660,661,662,663,664,665,666,700,701,702,703,704,705,740,741,742,743,744,739,779,780,781,782,783,822,821,820,819,860,861,862,901,823,392,393,394,395,435,475,515,514,513,473,474,434,433,432,472,512,552,553,554,555,594,634,674,754,714,794,795,755,635,592,593,633,632,673,672,712,713,752,753,793,792,675,396,397,436,437,438,439,479,478,477,476,516,517,518,519,558,557,596,597,598,599,556,636,637,638,639,679,678,677,676,716,717,718,719,799,758,757,756,796,797,798,759,362,363,364,365,366,367,405,403,402,442,443,444,445,446,447,406,448,449,486,566,606,646,686,726,526,525,565,485,605,645,685,725,724,723,722,522,523,483,482,607,608,609,604,603,727,728,729,451,452,412,413,373,374,375,415,416,456,457,414,453,458,531,532,534,535,536,537,533,571,611,651,691,731,732,733,693,653,613,573,575,615,655,695,735,775,697,657,617,577,578,618,658,738,737,736,698,696,656,616,576,574,614,654,694,734,774,692,652,612,572,455,417,376,372,411,450,491,492,493,494,495,496,497,498],
                maskIndexList: [8,9,10,13,14,23,24,25,26,27,28,29,38,39,40,41,42,43,46,47,48,50,51,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,102,103,104,105,106,107,108,109,110,111,112,113,114,117,118,119,120,128],
                editMode: false,
            }
        },
        computed: {
            list() {
                let imgIndex = 0;
                return defineRows.map((r) => {
                    return defineColumns.map((c) => {
                        const index = (r - 1) * defineColumns.length + c;
                        const destImage = this.images[imgIndex];
                        if (this.maskIndexList.includes(index) && destImage) {
                          let imgI = imgIndex;
                            imgIndex++;
                            return {
                                index: imgI,
                                src: destImage,
                                thumb: `${destImage}?x-oss-process=image/resize,w_200,m_lfit`,
                                class: "clickable"
                            };
                        }
                        return {
                            index: -1,
                            src: `assets/empty.png`,
                            thumb: `assets/empty.png`,
                            class: this.editMode && this.maskIndexList.includes(index) ? 'active' : '',
                        };
                    });
                });
            }
        },
        mounted() {
            setTimeout(() => {
                this.load();
            }, 1000)
            wxService.init().then(() => {
              this.inWeChat = true;
            })
        },
        methods: {
            load() {
                this.$nextTick(() => {
                    this.classList = ["wrap", "loaded"];
                });
                this.fetchImage();
            },
            handleAdded(up, files) {
                let file = files[0];
                if (file) {
                    service.getOSSParams(file.name)
                        .then((params) => {
                            up.setOption({
                                url: params.host,
                                multipart_params: params
                            });
                            up.start();
                        })
                }
            },

            handleUploaded(up, file, result) {
                const url = up.getOption("url");
                let key;
                try {
                    let res = JSON.parse(result.response);
                    if (res && res.status) {
                        key = `${url}/${res.data.filename}`;
                    }
                } catch (e) {
                    this.$refs.uploader.setErrorText("上传错误");
                    return;
                }
                console.log(key);
                service.saveResult(key)
                    .then(() => {
                        this.fetchImage();
                    })
            },

            fetchImage() {
                service.images()
                    .then((data) => {
                        this.images = data.images;
                        this.inWeChat = data.inWechat;
                    });
            },

            showBox(i) {
                console.log(i);
                if (i > -1) {
                    this.$refs.box.current(i);
                }
            },

            markItem(selected) {
              if (!this.editMode) {
                return false;
              }
              if (!this.maskIndexList.includes(selected)) {
                this.maskIndexList.push(selected);
              } else {
                this.maskIndexList = this.maskIndexList.filter((row) => {
                  return row != selected;
                })
              }
            }
        }
    }
</script>

<style lang="scss">
    body {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        padding: 0;
        margin: 0;
        background-size: 100% auto;
        background-position: center center;
        background: #3d3d3d;
        height: 100vh;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background:transparent url("./assets/body.jpg") center center no-repeat;
          filter:blur(5px);
          z-index:-1;
          background-size:cover;
        }
    }

    .wrap {
        background: url("./assets/bg.jpg") no-repeat;
        background-size: 100% auto;
        background-position: center center;
        transition: all 2s ease;
        opacity: 0;
        position: relative;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        &.loaded {
            opacity: 1;
        }
    }

    .wrapper {
        width: 100%;
        height: auto;
        mask-image: url("./assets/road-logo.svg");
        mask-mode: alpha; /* Can be changed in the live sample */
        mask-repeat: no-repeat;
        mask-size: 100% auto;
        mask-border-mode: alpha;
        background: url("./assets/road-logo.svg") no-repeat;
        background-size: 100% auto;
        background-position: center 0;

        &.editable {
          mask-image: none;
        }

        ul, li {
            padding: 0;
            margin: 0;
            list-style: none;
        }
    }

    .editable {
      li {
        border: 1px solid rgba(255,255,255,0.1);
        margin-left: -1px;
        cursor: pointer;

        .img {
          display: none;
        }

        &.active {
          background: rgba(255,255,255,0.2);
        }
      }

      .container li span {
        display: block;
      }
    }

    .container {
        display: flex;
        justify-content: space-between;

        img {
            width: 100%;
            border: 0;
            display: block;
            transition: all 0.8s ease;
            filter: brightness(120%);
            /*opacity:0.2;*/
            &:hover {
                filter: brightness(250%);
            }
            &.clickable {
                cursor: pointer;
            }
        }
        li {
            width: 100%;
            box-sizing: border-box;
            position: relative;
            height: 5vw;
            overflow: hidden;
            //background: #000000;

            span {
                display: none;
                font-size: 1.5em;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

          &.clickable {
            background-color: #ffffff;
          }
        }
    }

    .input-group {
        width: 100%;
        margin: 0 auto;
        position: absolute;
        left: 0;
        bottom: 1rem;
    }
@media only screen and (max-device-width: 768px) and (orientation: portrait) {
    //body {
    //    * {
    //        opacity: 0 !important;
    //    }
    //    height: 100vh;
    //    background: url("./assets/portrait-bg.gif") no-repeat #000000;
    //    background-position: center center;
    //}
}
@media (max-device-width: 768px) {
    .wrap {
        margin-top: -1.5em;
    }
}
html {
    font-size: 1.66vw;
}
@media (max-width: 768px) {
    html {
        font-size: 3.75vw;
    }
}
</style>
