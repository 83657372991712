import wx from "weixin-js-sdk";

const config = global.WX_CONFIG || {};

const shareInfo = {
    title: document.title,
    description: document.querySelector('meta[name="description"]').getAttribute("content") || "",
    image: document.querySelector("img.share").getAttribute("src")
};



export default {
    init() {
        return new Promise((resolve, reject) => {
            wx.ready(() => {
                wx.updateAppMessageShareData({
                    title: shareInfo.title, // 分享标题
                    desc: shareInfo.description, // 分享描述
                    link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: shareInfo.image
                });
                wx.updateTimelineShareData({
                    title: shareInfo.title, // 分享标题
                    link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: shareInfo.image, // 分享图标
                });
                resolve();
            });
            wx.error(reject);
            wx.config(config);
        });
    },
    wxUpload() {
        return (new Promise((resolve) => {
            wx.chooseImage({
                count: 1,
                sizeType: ['compressed'],
                sourceType: ['album', 'camera'],
                success(res) {
                    resolve(res.localIds);
                }
            });
        }))
        .then((localIds) => {
            return new Promise(resolve => {
                wx.uploadImage({
                    localId: localIds[0],
                    isShowProgressTips: 1,
                    success(res) {
                        resolve(res.serverId);
                    }
                });
            })
        })
    }
}