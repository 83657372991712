<template>
    <div class="input-group">
        <div class="btn-wrapper">
            <fieldset :disabled="loading">
            <button ref="uploadBtn"
                    type="button">{{showText}}</button>
            <div v-if="error" class="error-text" v-text="error"></div>
            </fieldset>
        </div>
    </div>
</template>
<script>
import plupload from "plupload";

const uploaderDefaultOption = {
    runtimes: 'html5,html4',
    headers: {
        "x-oss-object-acl": "public-read"
    },
    multi_selection: false,
    url : 'http://oss.aliyuncs.com',
    filters : {
        max_file_size : "10mb"
    }
};

export default {
    name: "vue-plupload",
    props: {
        text: {
            type: String,
            default: "Upload"
        },
        options: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },

    data() {
        return {
            filename: "",
            statusText: false,
            loading: false,
            errorText: false,
            uploader: null
        }
    },

    computed:{
        showText() {
            return this.statusText || this.text;
        },
        error() {
            if (this.errorText) {
                return JSON.stringify(this.errorText);
            }

            return false;
        }
    },

    methods: {
        renderUI() {

            this.$nextTick(() => {

                if (this.uploader) {
                    return;
                }

                let self = this;

                let opt = Object.assign(uploaderDefaultOption, self.options, {
                    browse_button: self.$refs.uploadBtn,
                });

                let uploader = new plupload.Uploader(opt);
                this.uploader = uploader;

                uploader.bind("PostInit", (up) => {
                    self.$emit("init", up)
                });

                uploader.bind("FilesAdded", (up, files) => {
                    const file = files[0],
                            mimeType = file.type,
                            isImg = /image\/(.*)/i.test(mimeType);

                    if (!isImg) {
                        this.errorText = "请选择照片上传";
                        up.removeFile(file);
                        return;
                    }

                    self.$emit("added", up, files);
                    this.errorText = false;
                    this.filename = files[0].name;
                });

                uploader.bind("UploadProgress", (up, file) => {
                    self.$emit("progress", up, file);

                    let speed = (up.total.bytesPerSec / 1024 / 1024).toFixed(2);
                    this.statusText = `上传中 ${file.percent}% ${speed} M/S`
                    this.loading = true;
                });

                uploader.bind("FileUploaded", (up, file, result) => {
                    self.$emit("uploaded", up, file, result)
                    this.statusText = `上传完毕`;
                    this.loading = false;
                    setTimeout(() => {
                        this.statusText = false;
                    }, 1000);
                });

                uploader.bind("Error", (up, err) => {
                    self.$emit("error", up, err);
                    this.errorText = err;
                    this.loading = false;
                });


                uploader.init();
            });
        },

        setErrorText(text) {
            this.errorText = text;
        }
    },

    mounted() {
        this.renderUI();
    },

    updated() {
        this.renderUI();
    }
};
</script>
<style lang="scss">
.error-text {
    color: red;
}
.btn-wrapper {
    min-width: 20vw;
    display: inline-block;
    margin: 0 auto;
    font-size: 0.58rem;

    button {
        color: #ffffff;
        font-size: 0.58rem;
        padding: 1em 4em;
        border:  1px solid #ffffff;
        background: transparent;
        cursor: pointer;
        border-radius: 5px;
        transition: all 0.5s ease;

        &:hover {
            background: #ffffff;
            color: #000000;
            border-color: #ffffff;
        }
    }
    fieldset {
        border: none;
        background: transparent;
    }
}
</style>